<template>
    <div>
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{$t('title.customers')}} </h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <span class="breadcrumb-item active">{{$t('title.customers')}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
            <div class="row">
                <div class="col-6">

                </div>
                <div class="col-6">
                    <div class="float-right">
                        <a @click="startEdit(0)" class="btn btn-info">{{ $t('actions.add') }}</a>
                    </div>
                    <div class="float-right form-group mg-b-0 mg-r-10">
                        <search :search="search" @search="doSearch"></search>
                    </div><!-- form-group -->
                    <div class="float-right mg-r-10">
                        <selectenumtype url="/api/enums"
                                        class="wd-300"
                                        :value="appVersion"
                                        v-model="appVersion"
                                        enumtype="appversion"
                                        @input="setAppVersion"
                                        emptyname="Alle">
                        </selectenumtype>
                    </div>
                    <div class="float-right mg-r-10 mg-t-5">
                        <label class="form-control-label" :for="$t('appversion')">{{$t('appversion')}}:</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="br-pagebody">
            <div class="br-section-wrapper">
                <div class="table-responsive">
                    <table v-if="initialized" class="table table-hover table-striped mg-b-0">
                        <thead>
                            <tr>
                                <th>{{ $t('name') }}</th>
                                <th>{{ $t('identifier') }}</th>
                                <th>{{ $t('domain') }}</th>
                                <th class="text-center">{{$t('users')}}</th>
                                <th class="text-right" width="200">{{ $t('title.details') }}</th>


                            </tr>
                        </thead>
                        <tbody v-if="list.length > 0">
                            <tr v-for="record in list" :key="record.id">
                                <td>{{record.name}} (<span v-if="record.appVersion == 0">oud</span><span v-else-if="record.appVersion == 1">1.0</span><span v-else>2.0</span>)</td>
                                <td>{{record.identifier}}</td>
                                <td>{{record.domain}}</td>
                                <td class="text-center"><button class="btn btn-icon btn-secondary" @click="startUsers(record.id)"><span class="mg-r-10 wd-30 rounded-circle">{{record.userCount}}</span><font-awesome-icon icon="list" /></button></td>
                                <td class="text-right" width="200">
                                    <button class="btn btn-icon btn-success mg-r-15" @click="startEdit(record.id)"><font-awesome-icon icon="edit" /></button>
                                    <button class="btn btn-icon btn-danger mg-r-15" @click="deleteRecord(record.id)"><font-awesome-icon icon="trash" /></button>

                                </td>

                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5">{{ $t('status.norecords') }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else><em>{{ $t('status.loading') }}</em></p>
                </div>
                <paging v-if="initialized" :current-page="page" :total-pages="totalPages" @page-changed="goToPage" />
            </div><!-- br-section-wrapper -->
        </div><!-- br-pagebody -->
    </div>
</template>

<script>
    import { LIST_SEARCH, LIST_PAGE } from '@/store/actions/listactions';
    import { CUSTOMERLIST_APPVERSION } from '@/store/actions/customerlistactions';
    import { AUTH_REQUEST_IMPERSONATE } from '@/store/actions/authactions';
    import { mapGetters, mapActions } from 'vuex';
    import toastr from 'toastr';

    export default {
        name: 'CustomerList',
        data() {
            return {
                initialized: false,
                totalPages: 0,
                list: [],

            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            search() {
                this.fetch();
            },
            page() {
                this.fetch();
            },
            appVersion() {
                this.fetch();
            }
        },
        methods: {
            startEdit(id) {
                this.$router.push({ name: 'customersEdit', params: { id: id } });
            },
            startUsers(id) {
                this.$router.push({ name: 'customerUsers', params: { id: id } });
            },
            async fetch() {
                if (this.$route.query.add) {
                    this.$router.push({ name: 'customersEdit', params: { id: -1 } });
                }
                const client = await this.$api.getClient();
                const resp = await client.GetCustomers({ id: 0, page: this.page, search: this.search, appVersion: this.appVersion });
                this.list = resp.data.list;
                this.totalPages = resp.data.totalPages;
                this.initialized = true;

            },
            async deleteRecord(id) {
                this.$dialog.confirm(this.$t('confirm.delete'))
                    .then(async () => {
                        const client = await this.$api.getClient();
                        try {
                            await client.DeleteEmployee(id);
                            toastr.success(this.$t('success.deleteRecord'), '');
                            await this.fetch();
                        } catch (err) {
                            toastr.error(this.$t('error.deleteRecord'), err);
                        }
                    })
                    .catch(() => { });
            },
            ...mapActions('auth', {
                doLogin: AUTH_REQUEST_IMPERSONATE
            }),
            ...mapActions('customerlist', {
                doSearch: LIST_SEARCH,
                goToPage: LIST_PAGE,
                setAppVersion: CUSTOMERLIST_APPVERSION
            }),

        },
        computed: {
            ...mapGetters('customerlist', [
                'page',
                'search',
                'appVersion'
            ])
        }
    }
</script>
